import type { AppLocale } from '~/data/domain/app.locale';

export const newsKeys = {
  all: [{ scope: 'news' }] as const,
  mainPage: (locale: AppLocale) => [{ ...newsKeys.all[0], entity: 'mainPage', locale }] as const,
  lists: () => [{ ...newsKeys.all[0], entity: 'list' }] as const,
  list: (locale: AppLocale) => [{ ...newsKeys.lists()[0], locale }] as const,
  dappList: (slug: string, locale: AppLocale) =>
    [{ ...newsKeys.lists()[0], entity: 'dappList', slug, locale }] as const,
};
